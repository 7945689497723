.sidebar-section {
    width: 80px;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: #11111c;
    z-index: 20;
}

.sidebar-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;
}

.sidebar-item {
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.sidebar-item a {
    text-decoration: none;
}

.sidebar-item a i {
    font-size: 24px;
    color: white;
}

.sidebar-item .icon-title-container {
    display: none;
}

.sidebar-item:hover .icon-title-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 80px;
    top: 0;
    width: 150px;
    background-color: #1c1c29;
}

.sidebar-item:hover a i {
    color: var(--primary-ultra-lite-color);
}

.sidebar-item:hover {
    background-color: #1c1c29;
}

.icon-title-container h1 a {
    font-size: 18px;
    height: 60px;
    color: white;
    padding: 0px 10px;
    display: flex;
    align-items: center;
}

.icon-title-container h1 a:hover {
    color: white !important;
}

.sidebar-subitems {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.icon-title-container a {
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding: 10px;
}

.icon-title-container a:hover {
    color: var(--primary-ultra-lite-color);
}

.sidebar-logo-wrapper {
    width: 60px;
    height: 60px;
    background-color: #1c1c29;
    border-radius: 15px;
    margin: 20px auto;
}

.sidebar-logo-wrapper img {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.sidebar-item.logout {
    position: absolute;
    bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}