@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Bai Jamjuree", sans-serif;
}

:root {
  --primary-color: #2d4798;
  --primary-lite-color: #5e7fe1;
  --primary-ultra-lite-color: #9eb6ff;
  --secondary-color: #4b4a4c;
  --secondary-lite-color: #999999;
  --secondary-ultra-lite-color: #e0e0e0;
}