.order-section {
    width: 100%;
    background-color: #f6f6f6;
    min-height: 100%;
    margin: 0;
    position: relative;
}


.order-wraper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.order-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 20px;
    border: 1px solid var(--secondary-ultra-lite-color);
    box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.2);
}



.order-content {
    display: flex;
    width: 100%;
}

.order-content p {
    flex: 1;
    font-size: 16px;
    padding: 10px;
    font-weight: 500;
}

.order-content a {
    flex: 1;
    margin: 0;
    padding: 10px;
    font-weight: 800;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.order-content a span {
    border: 1px solid black;
    padding: 5px;
    border-radius: 3px;
    background-color: #f6f6f6;
}

.order-content a:hover {
    color: var(--secondary-color);
}

.order-id span {
    color: var(--primary-color);
    font-weight: 800;
}

.order-status-select,
.order-status span {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 700;
    margin-left: 10px;
    margin-right: 5px;
}

.order-status button {
    padding: 5px 10px;
    background-color: white;
    border: solid green;
    border-radius: 3px;
    color: var(--primary-color);
    font-weight: 800;
    transition: 0.25s;
    cursor: pointer;
}

.order-status button:hover {
    background-color: green;
    color: white;
}

.order-status-select.new,
.order-status.new span {
    background-color: rgb(255, 219, 77);
}

.order-status-select.under-review,
.order-status.under-review span {
    background-color: var(--primary-color);
    color: white;
}

.order-status-select.accepted,
.order-status.accepted span {
    background-color: green;
    color: white;
}

.order-status-select.rejected,
.order-status.rejected span {
    background-color: rgb(196, 0, 0);
    color: white;
}


.order-content.header {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--secondary-ultra-lite-color);
    padding-bottom: 10px;
}

.filter-sort {
    margin-left: 100px;
    margin-right: 20px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.filter-sort div {
    flex: 1;
    display: flex;
    justify-content: center;
}

.filter-sort input {
    padding: 5px;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 5px;
    width: 300px;
}

.filter-sort button {
    background-color: var(--secondary-color);
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 600;
}

.filter-sort button:hover {
    background-color: white;
    color: black;
}

.order-sort {
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.order-sort i {
    margin-left: 20px;
}

.order-filter {
    display: flex;
    align-items: center;
}

.order-filter label {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-right: 20px;
}

.order-filter select {
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    width: 150px;
}

.order-summary-container {
    margin-left: 100px;
    margin-right: 20px;
}

.order-lists {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-ultra-lite-color);
}

.order-lists img {
    width: 200px;
    height: 200px;
}

.order-specs {
    padding-left: 50px;
    width: 500px;
}

.order-specs a {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    transition: 0.1s;
}

.order-specs a:hover {
    color: var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
}

.order-phy-prop p {
    padding: 2px 0px;
}

.order-specs h3 {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: 700;
}

.qty {
    padding-left: 50px;
    font-weight: 600;
}

.order-summary-section {
    background-color: #f6f6f6;
    min-height: 100vh;
    position: absolute;
    min-width: 100vw;
}


.order-wrapper {
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
}

.order-wrapper .order-content.header {
    padding: 20px;
}

.order-index {
    font-size: 22px;
    padding: 20px;
    padding-right: 50px;
    color: var(--secondary-color);
}


.user_details {
    padding: 20px;
    background-color: white;
    border-top: 20px solid #f6f6f6;
    position: relative;
    margin-bottom: 100px;
}

.user_details h3 {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-ultra-lite-color);
}

.user_details .order-content {
    padding-top: 20px;
    flex-wrap: wrap;
}