.dashboard-section {
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
}

.dashboard-container {
    width: calc(100% - 80px);
    margin-left: auto;
    height: 100%;
    position: relative;
}

.login-details-widget {
    background-color: var(--primary-color);
    width: 300px;
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    color: white;
    position: absolute;
    right: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.login-details-widget h1 {
    font-size: 24px;
    padding-bottom: 5px;
}

.login-details-widget h2 {
    font-size: 20px;
    padding-bottom: 5px;
}

.login-details-widget h3 {
    font-size: 16px;
}