.add-product-section {
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
}

.add-product-container {
    width: calc(100% - 80px);
    margin-left: auto;
    height: 100%;
    position: relative;
}


.page-header {
    padding: 20px;
    background-color: #f4f4f4;
    width: fit-content;
    margin: 20px;
    border-left: 5px solid var(--primary-color);
    color: var(--secondary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 22px;
    border-radius: 5px;
}

.add-product-form {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    border: 1px solid var(--secondary-ultra-lite-color);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 50px;
}

.add-product-form.next-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    box-shadow: none;
    border: none;
    padding-bottom: 100px;
}

.add-product-form.next-btn button {
    margin: 0;
}

.add-product-form-items-pair {
    display: flex;
    width: 100%;
}

.form-input-lable-pair {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 20px 20px;
}

.form-input-lable-pair.disc {
    width: 100%;
}

.form-input-lable-pair input,
.form-input-lable-pair textarea {
    padding: 15px;
    font-size: 14px;
    border: 2px solid var(--secondary-color);
    border-radius: 3px;
    font-weight: 600;
    color: var(--secondary-color);
}

.form-input-lable-pair input:focus {
    border: 2px solid var(--primary-color);
}

.form-input-lable-pair label {
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    color: var(--primary-color);
    top: 10px;
    left: 10px;
    background-color: white;
    width: fit-content;
    padding: 0px 10px;
}

.form-input-lable-pair label span {
    text-transform: none;
}

.form-next-btn {
    padding: 10px 20px;
    margin: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.form-next-btn:hover {
    background-color: var(--primary-color);
}

.add-product-form-items-pair {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-title {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary-color);
    border-bottom: 1px solid var(--secondary-ultra-lite-color);
    margin: 10px 20px;
    text-align: center;
    padding-bottom: 20px;
}


/******addprocess********/

.add-process-input-container {
    display: flex;
    margin: 20px;
    align-items: center;
}

.add-process-input-container h4 {
    font-size: 22px;
    padding-right: 15px;
    color: var(--secondary-color);
}

.add-process-input-container i {
    font-size: 22px;
    color: var(--primary-color);
    cursor: pointer;
}

.add-process-input-container i:hover {
    color: var(--secondary-color);
}

.add-product-form-items-pair.process {
    justify-content: flex-start;
    align-items: center;
}

.add-product-form-items-pair.process .form-input-lable-pair {
    width: 300px;
}

.add-product-form-items-pair.process .form-input-lable-pair.index {
    width: 100px;
}



.delete-icon i {
    font-size: 22px;
    color: #c60000;
    padding-top: 10px;
    cursor: pointer;
    margin: 20px;
}


.add-product-form-items-pair.sub {
    width: 100%;
}


.form-input-lable-pair.pd {
    width: 20%;
}

.form-control-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.form-control-btn button {
    font-size: 16px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.form-control-btn button:hover {
    background-color: var(--primary-color);
}