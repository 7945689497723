.login-section {
    background: linear-gradient(90deg, var(--primary-lite-color), var(--primary-ultra-lite-color));
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.screen {
    background: linear-gradient(90deg, var(--primary-lite-color), var(--primary-ultra-lite-color));
    position: relative;
    height: 600px;
    width: 360px;
    box-shadow: 0px 0px 24px var(--primary-lite-color);
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
}

.screen__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
}

.screen__background__shape {
    transform: rotate(45deg);
    position: absolute;
}

.screen__background__shape1 {
    height: 520px;
    width: 520px;
    background: #FFF;
    top: -50px;
    right: 120px;
    border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
    height: 220px;
    width: 220px;
    background: var(--primary-color);
    top: -172px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape3 {
    height: 540px;
    width: 190px;
    background: linear-gradient(270deg, var(--primary-color), var(--primary-lite-color));
    top: -24px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape4 {
    height: 400px;
    width: 200px;
    background: #fff;
    top: 420px;
    right: 50px;
    border-radius: 60px;
}

.login {
    width: 320px;
    padding: 30px;
    padding-top: 156px;
}

.login__field {
    padding: 20px 0px;
    position: relative;
}

.login__icon {
    position: absolute;
    top: 30px;
    color: var(--primary-color);
}

.login__input {
    border: none;
    border-bottom: 2px solid var(--secondary-lite-color);
    background: none;
    padding: 10px;
    padding-left: 24px;
    font-weight: 700;
    width: 75%;
    transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
    outline: none;
    border-bottom-color: var(--secondary-lite-color);
}

.login__submit {
    background: #fff;
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 26px;
    border: 1px solid var(--secondary-ultra-lite-color);
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--primary-color);
    box-shadow: 0px 2px 2px var(--secondary-lite-color);
    cursor: pointer;
    transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
    border-color: var(--secondary-lite-color);
    outline: none;
}

.button__icon {
    font-size: 24px;
    margin-left: auto;
    color: var(--primary-color);
}

.social-login {
    position: absolute;
    height: 140px;
    width: 160px;
    text-align: center;
    bottom: 0px;
    right: 0px;
    color: #fff;
    padding-top: 25px;
    padding-right: 15px;
}

.social-login img {
    padding-top: 25px;
    width: 150px;
    mix-blend-mode: soft-light;
}